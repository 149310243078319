import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import seicon from '../../../assets/icons/seicon.png';
import './SELoginButton.scss';


const TwitchLoginButton: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Button
        className="se-login-button"
        variant="contained"
        size="large"
        startIcon={<img src={seicon} className="base-icon da" alt="StreamElements Icon"/>}
        onClick={() => {}}
      >
        {t('common.connectSE')}
      </Button>
    </>
  );
};

export default TwitchLoginButton;
