import { PurchaseStatusEnum } from '../models/purchase';

export const COLORS = {
  PURCHASE_STATUS: {
    [PurchaseStatusEnum.Deleted]: '#F08080',
    [PurchaseStatusEnum.Processed]: '#AED581',
  },
  WHEEL: [
    '#00b6ac',
    '#4a91ae',
    '#63c575',
    '#f85842',
    '#5d60e2',
    '#e58221',
    '#966062',
    '#7daee8',
    '#6690ad',
    '#2a8bea',
    '#e385cf',
    '#e35182',
    '#36b5e9',
    '#355f56',
    '#375f4e',
    '#519cc0',
    '#d17175',
    '#b062e7',
    '#abcc9e',
    '#80c18b',
    '#717ff6',
    '#7c9f30',
    '#c3747f',
    '#ea5461',
    '#b87dc8',
    '#978fbc',
    '#df5f56',
    '#f3bf41',
    '#FFCE24',
    '#EA631D',
    '#E33055',
    '#0085CB',
    '#275483',
    '#3CB787',
    '#80A649',
    '#E3904C',
    '#C94D51',
    '#5B4749',
  ],
};
